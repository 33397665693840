import common from './components/common'
import modal from './components/modal'
import user from './components/user'
import geolocation from './components/geolocation'
import AudioPlayer from './components/AudioPlayer'
import header from './components/header'

const Main = {

  init: () => {
    new AudioPlayer('#container-player')
  }

}

common.autocompleteSearch()
common.onScroll()
common.controlMobileSearch()
common.closeBanner()
common.controlMobileMenu()
//common.renderBanner()
common.initMetrics()
geolocation.userGeolocation()
modal.controlModal()
user.userInfo()
user.controlLoggedMenu()
user.controlFavoritesLocalesBreadcrumb()
header.controlMenu()
Main.init()