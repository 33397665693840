export default class AudioPlayer {

  constructor(selector = '#container-player') {
    this.container = document.getElementById(selector);
    this.selector = selector;
    this.player = document.getElementById('apc-player');
    this.isPlaying = false;
    this.progressbar = document.getElementById('apc-progress-bar');
    this.volume = document.getElementById('apc-volume');
    this.playlistIndex = 0;
    this.init();
  }

  /**
   * Audio Player play/pause management
   */
  togglePlay() {
    if (this.player.paused === false) {
      this.player.pause();
      this.isPlaying = false;
      $('.apc-play-button').removeClass('fa-pause').addClass('fa-play');
    } else {
      this.player.play();
      this.isPlaying = true;
      $('.apc-play-button').removeClass('fa-play').addClass('fa-pause');
    }
  }

  /**
   * Forward / Backward button action
   * @param {number} step 
   */
  skip(step) {
    const order = Number(step) + Number(this.playlistIndex);
    const element = $(`.apc-track${order}`);
    this.changePlayerData(element, order);
  };

  /**
   * Playlist click action
   * @param {number} order 
   */
  skipTo(order) {
    const element = $(`.apc-track${order}`);
    this.changePlayerData(element, order);
  };

  /**
   * Update player info
   * @param {HTMLElement} element 
   * @param {number} order 
   */
  changePlayerData(element, order) {
    if(element.length) {
      const url = element.data('url');
      const image = element.data('image');
      const description = element.data('description');
      const title = element.data('title');
      const pubDate = element.data('pubdate');

      $('.apc-image-cover').attr('src', image);
      $('.apc-description').html(description);
      $('.apc-title').html(title);
      $('.apc-pub-date').html(pubDate);
      this.togglePlay();
      this.player.setAttribute("src", url);
      this.player.load();
      this.togglePlay();
      this.playlistIndex = order;
      this.highlightItem(order);
    }
  }

  /**
   * Highlight Title playlist
   * @param {number} order 
   */
  highlightItem(order) {
    $('.apc-list-title').removeClass('-blue');
    $(`.apc-track${order}`)
      .nextAll('.apc-info:first')
      .children('.apc-list-title')
      .addClass('-blue');
  }

  /**
   * Seek in seconds
   * @param {number} seconds 
   */
  jumpTo(seconds) {
    this.player.currentTime = seconds + this.player.currentTime;
  }

  /**
   * Caculate total audio length
   * @param {number} length 
   */
  calculateTotalValue(length) {
    if (isNaN(length)) return '00:00';
    const minutes = Math.floor(length / 60),
      seconds_int = length - minutes * 60,
      seconds_str = seconds_int.toString(),
      seconds = seconds_str.substr(0, 2),
      time = minutes + ':' + seconds
  
    return time;
  };

  /**
   * Calculate total current audio time
   * @param {number} currentTime 
   */
  calculateCurrentValue(currentTime) {
    const current_hour = parseInt(currentTime / 3600) % 24,
      current_minute = parseInt(currentTime / 60) % 60,
      current_seconds_long = currentTime % 60,
      current_seconds = Math.floor(current_seconds_long),
      current_time = (current_minute < 10 ? "0" + current_minute : current_minute) + ":" + (current_seconds < 10 ? "0" + current_seconds : current_seconds);
  
    return current_time;
  };

  /**
   * Init progress bar
   */
  initProgressBar() {
    const length = this.player.duration
    const current_time = this.player.currentTime;
  
    // calculate total length of value
    const totalLength = this.calculateTotalValue(length);
    $('.apc-end-time').html(totalLength);
  
    // calculate current value time
    const currentTime = this.calculateCurrentValue(current_time);
    $('.apc-start-time').html(currentTime);
    this.progressbar.value = (this.player.currentTime / this.player.duration);
    this.progressbar.addEventListener("click", this.seek.bind(this));
  
    if (this.player.currentTime == this.player.duration) {
      $('.apc-play-button').removeClass('fa-pause').addClass('fa-play');
    }
  };

  /**
   * Volume slider action
   * @param {HTMLElement} element 
   */
  changeVolume(element) {
    this.player.volume = element.srcElement.value;
  };

  /**
   * Progress Bar click event
   * @param {MouseEvent} event 
   */
  seek(event) {
    const percent = event.offsetX / event.srcElement.offsetWidth;
    this.player.currentTime = percent * this.player.duration;
    this.progressbar.value = percent / 100;
  }

  /**
   * Init Player
   */
  init() {
    const playBtn = document.getElementsByClassName('apc-play-button');
    const skipBtn = document.getElementsByClassName('apc-skip');
    const items = document.getElementsByClassName('apc-item');
    const undoBtn = document.getElementById('apc-undo');
    const redoBtn = document.getElementById('apc-redo');
    const crop = document.getElementsByClassName('crop');

    undoBtn.addEventListener('click', this.jumpTo.bind(this, -15));
    redoBtn.addEventListener('click', this.jumpTo.bind(this, 15));
    this.player.ontimeupdate = this.initProgressBar.bind(this);
    this.volume.oninput = this.changeVolume.bind(this);
    
    Array.prototype.map.call(crop, (element) => {
      element.addEventListener('click', function (element) {
        $(element.srcElement).toggleClass('_block')
      });
    });
    Array.prototype.map.call(items, (element) => {
      element.addEventListener('click', this.skipTo.bind(this, element.getAttribute('data-index')));
    });
    Array.prototype.map.call(skipBtn, (element) => {
      if (element.classList.contains('forward')) {
        element.addEventListener('click', this.skip.bind(this, -1));
      } else {
        element.addEventListener('click', this.skip.bind(this, 1));
      }
    });
    Array.prototype.map.call(playBtn, (element) => {
      element.addEventListener('click', this.togglePlay.bind(this));
    });
  }
}
